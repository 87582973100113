import { userState } from "@/@stores/stores";
import { useRecoilState } from "recoil";
import { useRouter } from "next/navigation";
import Cookie from "js-cookie";

import toast from "react-hot-toast";
import { useState } from "react";
import { FormProps } from "@/@types";
import useCart from "./useCart";

export default function useAuth() {
  const router = useRouter();

  const [user, setUser] = useRecoilState(userState);
  const [loading, setLoading] = useState(false);

  const { resetCart } = useCart();

  async function handleSecret({ secret }: { secret: string }) {
    setLoading(true);
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/secret`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          secret,
        }),
      }
    );

    if (response.status === 200) {
      const { secret } = await response.json();

      Cookie.set(
        "secret",
        secret,
        // 10 years
        { expires: 3650 }
      );

      toast.success("Showroom liberado com sucesso!");
    } else {
      const { error } = await response.json();
      toast.error(error);
    }

    setLoading(false);

    return response.status === 200;
  }

  async function saveAuth({
    token,
    name,
    sobrenome,
    uuid,
    back,
    cpf_cnpj,
    email,
    telefone,
    endereco,
  }: {
    token: string;
    name: string;
    sobrenome: string;
    uuid: string;
    back?: boolean;
    cpf_cnpj: string;
    email: string;
    telefone: string;
    endereco: string;
  }) {
    Cookie.set(
      "auth",
      token,
      // 10 years
      { expires: 3650 }
    );

    setUser({ name, sobrenome, uuid, cpf_cnpj, email, telefone, endereco });
    router.refresh();

    if (back) {
      router.back();
    }

    toast.success("Bem vindo!");
  }

  async function resetPassword({ email }: { email: string }) {
    setLoading(true);
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users/reset_password`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
        }),
      }
    );

    if (response.ok) {
      toast.success("E-mail resetado com sucesso!");
    } else {
      const { error } = await response.json();
      toast.error(error);
    }
    setLoading(false);
  }

  async function handleLogin({
    email,
    password,
    back = false,
  }: {
    email: string;
    password: string;
    back?: boolean;
  }) {
    setLoading(true);
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      }
    );

    if (response.ok) {
      const {
        token,
        name,
        sobrenome,
        uuid,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      } = await response.json();

      saveAuth({
        token,
        name,
        sobrenome,
        uuid,
        back,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      });
    } else {
      const { error } = await response.json();
      toast.error(error);
    }

    setLoading(false);
  }

  async function handleLogout({ back = false }: { back?: boolean }) {
    setLoading(true);

    Cookie.remove("auth");
    setUser(null);

    resetCart();

    router.refresh();

    if (back) {
      router.back();
    }

    toast.success("Até mais!");
    setLoading(false);
  }

  async function handleRegister(data: FormProps) {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: data }),
      }
    );

    if (response.ok) {
      const {
        token,
        name,
        sobrenome,
        uuid,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      } = await response.json();
      console.log(token, name, uuid);
      await saveAuth({
        token,
        name,
        sobrenome,
        uuid,
        back: true,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      });
    } else {
      const { error } = await response.json();
      toast.error(error);
    }
  }

  async function handleUpdate(data: FormProps) {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users/update`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${Cookie.get("auth")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: data }),
      }
    );

    if (response.ok) {
      toast.success("Dados atualizados com sucesso!");
    } else {
      const { error } = await response.json();
      toast.error(error);
    }
  }

  async function handleLoginViaDocument(cpf_cnpj: string, event_date: string) {
    setLoading(true);
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users/login_via_document`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cpf_cnpj, event_date }),
      }
    );

    if (response.ok) {
      const {
        token,
        name,
        sobrenome,
        uuid,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      } = await response.json();

      saveAuth({
        token,
        name,
        sobrenome,
        uuid,
        back: false,
        cpf_cnpj,
        email,
        telefone,
        endereco,
      });
    } else {
      const { error } = await response.json();
      toast.error(error);
    }

    setLoading(false);
  }

  async function checkUserExists(cpf_cnpj: string) {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/api/users/check_exists`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cpf_cnpj }),
      }
    );

    const responseJson = await response.json();

    return {
      exists: responseJson.exists,
      bloc: responseJson.bloc,
      user: responseJson.user,
    };
  }

  return {
    handleLogin,
    handleLogout,
    saveAuth,
    handleRegister,
    handleUpdate,
    resetPassword,
    handleSecret,
    user,
    loading,
    handleLoginViaDocument,
    checkUserExists,
  };
}
